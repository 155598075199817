
<template>
    <div v-if="stock_specification">
    شرکت<br>
    نام شرکت: {{ stock_specification.name_sherkat ? stock_specification.name_sherkat : '--' }} <br>
    سال مالی: {{ stock_specification.sal_mali ? stock_specification.sal_mali : '--' }} <br>
    توضیحات: {{ stock_specification.description ? stock_specification.description : '--' }} <br>
    ایمیل: {{ stock_specification.email ? stock_specification.email : '--' }} <br>
    وب سایت: {{ stock_specification.website ? stock_specification.website : '--' }} <br>
    -----------------------------------------------<br>
    اطلاعات تماس<br>
    <div v-for="(item, index) in stock_specification_contact" :key="index" v-if="stock_specification_contact">
    {{ item.type }}<br>
    {{ item.address }}<br>
    تلفن : {{ item.tel }}<br>
    دورنگار : {{ item.fax }}<br>
    ##############<br>
    </div>
    ----------------------------------------------<br>
    نمادها<br>
    <div v-for="(item, index) in stock_specification_instruments" :key="index" v-if="stock_specification_instruments">
    نام : {{ item.name }}<br>
    نام کوتاه نماد : {{ item.nemad }}<br>
    نوع : {{ item.type }}<br>
    بازار معاملاتی : {{ item.flow.title }}<br>
    بازار : {{ item.bazar }}<br>
    تابلوی معاملاتی نماد : {{ item.tablo_moamelati }}<br>
    کد بورسی : {{ item.code_bourse }}<br>
    گروه نماد : {{ item.group }}<br>
    کد ISIN : {{ item.ISIN }}<br>
    ##############<br>
    </div>
    ----------------------------------------------<br>
    اعضای هیات مدیره<br>
    ----------------------------------------------<br>
    </div>
</template>

<script>
export default {
    name: 'StockSpecification',
    data() {
        return {
            stock_specification : null,
            stock_specification_contact : null,
            stock_specification_instruments : null,
            stock_specification_board : null,
        }
    },
    mounted() {
        this.getProfileSpecification();
    },
    methods: {
        getProfileSpecification() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-specification/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_specification = api_response.data.response.specification.body.response.data;
                        this.stock_specification_contact = JSON.parse(this.stock_specification.contact);
                        this.stock_specification_instruments = JSON.parse(this.stock_specification.instruments);
                        this.stock_specification_board = api_response.data.response.board.body.response.items;
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>